import styled from "styled-components";
import UploadSingleFile from "../../../../../components/UploadSingleFile";
import UploadVideoLink from "../../../../../components/UploadVideoLink";

export const WrapperForm = styled.div`
    background: rgba(242, 242, 242, 1);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 25px 35px;
    margin-bottom: 25px;
    position: relative;

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const MediaContainerLeft = styled(UploadSingleFile)`
    margin-top: 235px;
    height: 500px;
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            height: 500px;
            width: 100%;
            display: block;
            border-radius: 10px;
        }
    }

    .upload-button {
        height: 500px;
        width: 100%;
        display: block;
        border-radius: 10px;
    }

    .uploaded-file {
        height: 500px;
        width: 100%;

        img {
            height: 500px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;

export const MediaContainer = styled(UploadSingleFile)`
    height: 300px;
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            height: 300px;
            width: 100%;
            display: block;
            border-radius: 10px;
        }
    }

    .upload-button {
        height: 300px;
        width: 100%;
        display: block;
        border-radius: 10px;
    }

    .uploaded-file {
        height: 300px;
        width: 100%;

        img {
            height: 300px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;

export const MediaVideoContainer = styled(UploadVideoLink)`
    height: 300px;
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .upload-button {
        height: 300px;
        width: 100%;
        display: block;
        border-radius: 10px;
    }

    .uploaded-file {
        height: 300px;
        width: 100%;

        embed {
            height: 300px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;

export const PreviewFeatureVideo = styled.div`
    height: 300px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    border: 1px dashed #00714b;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        height: 300px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        border: 0;
        overflow: hidden;
    }

    .anticon.anticon-play-circle {
        font-size: 60px;
        color: #00714b;
    }
`;

export const ToggleSlider = styled.div`
    position: relative;
    display: inline-block;
    width: 38px;
    height: 18px;

    label {
        display: block;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`;

export const ToggleSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
