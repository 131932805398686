/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import upperCase from "lodash/upperCase";
import { Radio, Row, Table } from "antd";
import { Spin, Space, Modal, message } from "antd";
import {
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    UnorderedListOutlined,
    PlaySquareTwoTone,
    LinkOutlined,
    CopyOutlined,
} from "@ant-design/icons";

import ContentContainer from "../../../../components/ContentContainer";
import CustomDrawer from "../../../../components/CustomDrawer";
import CustomTable from "../../../../components/CustomTable";
import PdfViewerModal from "../../../../components/PdfViewerModal";
import { userPermission } from "../../../../util/user-access";
import {
    UPLOAD_CONTRACT_ENTRY,
    GET_CONTRACT_LIST,
    GET_CONTRACT_ENTRY,
    DELETE_ENTRY,
    UPDATE_ENTRY,
    GET_FILE_LIST,
    FIND_ENTRY,
} from "../../graphql";

import Form from "./form";

import { Context } from "../../../../context";
import {
    brandsUrls,
    columns as cols,
    defaultInitialValue,
    fileColumns,
    validationSchema,
} from "./constant";

import { Document } from "../../../../entities/Document";
import { DocumentMapper } from "../../dto/DocumentMapper";
import axios from "axios";
import { StyledClipboard } from "./styled";

const Documents: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { id } = useParams();

    const {
        state: {
            // brand: brandData,
            user,
        },
    } = useContext(Context);

    const isAlveoManageContract = user?.roles[0]?.name === "ALVEO-DOC";
    const [fileColumn, setFileColumn] = useState(fileColumns);
    const [columns, setColumns] = useState<any[]>(cols);
    const [documentsList, setDocumentsList] = useState<any[]>([]);
    const [viewDocument, setViewDocument] = useState<string>("");
    const [sortBy, setSortBy] = useState<string>("created_at");
    const [sortOrder, setSortOrder] = useState<string>("DESC");
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [showSlider, setShowSlider] = useState<boolean>(false);
    const [reinitialize, setReinitialize] = useState(false);
    const [pageSize, setPageSize] = useState<number>(10);
    const [initialValue, setInitialValue] = useState(defaultInitialValue);
    const [viewFiles, setViewFiles] = useState(false);
    const [selected, setSelected] = useState<any>();
    const [selectedBrand, setSelectedBrand] = useState("");
    const [generatedUrl, setGeneratedUrl] = useState<null | string>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [removeDocument, { loading: removeDocumentLoading }] = useMutation(DELETE_ENTRY);
    const [findEntry, { data: entryData }] = useLazyQuery(FIND_ENTRY, {
        fetchPolicy: "no-cache",
        partialRefetch: true,
    });
    const [
        updateDocument,
        // , { loading: updateDocumentLoading }
    ] = useMutation(UPDATE_ENTRY);

    const [getContract, { data: contractData, loading: getDocumentLoading }] = useLazyQuery(
        GET_CONTRACT_ENTRY,
        {
            fetchPolicy: "no-cache",
            variables: {
                id: id,
            },
        }
    );

    const [
        getContractFiles,
        {
            data: contractFiles,
            //  loading: getFilesLoading
        },
    ] = useLazyQuery(GET_FILE_LIST, {
        fetchPolicy: "no-cache",
    });

    const [getContractList, { data }] = useLazyQuery(GET_CONTRACT_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            name: " ",
            pageSize: pageSize,
            page,
        },
    });

    useEffect(() => {
        console.log(data);
    }, [data]);

    const getFile = async (file_link: string) => {
        const result = await axios.get(`${process.env.REACT_APP_CONTENT_URL}/${file_link}`);
        return result;
    };

    useEffect(() => {
        if (contractData) {
            const { findContractDocument } = contractData;
            const {
                file: { file_link, name, file_alias, version },
                ...rest
            } = findContractDocument;

            const values = {
                file_name: name,
                file_link,
                file_alias,
                version,
                file: {
                    status: "done",
                },
                ...rest,
            };

            setInitialValue(values);
            setReinitialize(true);
        }
    }, [contractData]);

    const [modifyDocument] = useMutation(UPLOAD_CONTRACT_ENTRY);

    const resetForm = () => {
        setInitialValue(defaultInitialValue);
        setReinitialize(true);
    };

    useEffect(() => {
        getContractList({
            variables: {
                name: " ",
                pageSize,
                page,
            },
        });
    }, [pageSize, page]);

    const onSearch = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        if (search) {
            findEntry({
                variables: {
                    name: search,
                },
            });
        }
    }, [search]);

    useEffect(() => {
        if (search && entryData && entryData.filterContractDocuments) {
            const res = entryData.filterContractDocuments.map((info: any) =>
                Object.assign([], DocumentMapper.map({ ...info }))
            );
            setDocumentsList(res);
            setTotal(res.length);
        } else if (data && data.searchContractDocuments) {
            const { contract_documents, total_count } = data.searchContractDocuments;
            const res = contract_documents.map((info: any) =>
                Object.assign([], DocumentMapper.map({ ...info }))
            );
            setDocumentsList(res);
            setTotal(total_count);
        }
    }, [data, entryData, search]);

    const handleSlider = () => {
        setShowSlider(!showSlider);
        if (showSlider) {
            history.push("/manage-contracts");
            resetForm();
        }
    };

    const handleSort = (field: string, order: string) => {
        setSortBy(field);
        setSortOrder(upperCase(order));
    };

    const handlePageSize = async (pageSize: number) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const handleDelete = async (record) => {
        const { confirm } = Modal;

        confirm({
            title: "Delete Document?",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this document?",
            async onOk() {
                try {
                    await removeDocument({
                        variables: {
                            id: record.id,
                        },
                    });
                    getContractList({
                        variables: {
                            name: " ",
                            pageSize,
                            page,
                        },
                    });
                    message.success("Successfully deleted document");
                } catch {
                    message.error("Something went wrong");
                } finally {
                    return;
                }
            },
            onCancel() {
                return;
            },
        });
    };

    const handleView = (record: any) => {
        getFile(record.file.file_link).then((res: any) => {
            setViewDocument(res.config.url);
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const viewPreviousFiles = (id) => {
        setViewFiles(true);
        getContractFiles({
            variables: {
                id,
            },
        });
    };

    const handleEdit = (record: Document) => {
        getContract();
        history.push(`${match.path}/${record.id}`);
    };

    useEffect(() => {
        id && setShowSlider(true);
    }, [id]);

    useEffect(() => {
        const newColumns: any = columns;
        newColumns.find((col) => col.key === "actions").render = (record) => (
            <Space size="middle" style={{ fontSize: "1.5em" }}>
                <SearchOutlined onClick={() => handleView(record)} />
                {}
                {userPermission(user?.roles[0]?.permissions, "update:contract_document_entry") ? (
                    <EditOutlined style={{ display: "block" }} onClick={() => handleEdit(record)} />
                ) : null}

                {userPermission(user?.roles[0]?.permissions, "delete:contract_document_entry") ? (
                    <DeleteOutlined
                        style={{ display: "block" }}
                        onClick={() => handleDelete(record)}
                    />
                ) : null}
                {userPermission(user?.roles[0]?.permissions, "read:contract_document_entry") ? (
                    <UnorderedListOutlined
                        style={{ display: "block" }}
                        onClick={() => viewPreviousFiles(record.id)}
                    />
                ) : null}
                {userPermission(user?.roles[0]?.permissions, "create:contract_document_url") ? (
                    <LinkOutlined
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelected(record);
                        }}
                    />
                ) : null}
            </Space>
        );
        setColumns(newColumns);
    }, []);

    useEffect(() => {
        if (!isModalOpen) {
            setSelectedBrand("");
            setSelected(null);
            setGeneratedUrl("");
        }
    }, [isModalOpen]);

    useEffect(() => {
        const newColumns: any = fileColumn;

        newColumns.find((col) => col.key === "actions").render = (record) => (
            <Space size="middle" style={{ fontSize: "1.5em" }}>
                <PlaySquareTwoTone twoToneColor="#46ce2f" onClick={() => handleView(record)} />
                {userPermission(user?.roles[0]?.permissions, "create:contract_document_url") ? (
                    <LinkOutlined
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelected(record);
                        }}
                    />
                ) : null}
            </Space>
        );

        setFileColumn(newColumns);
    }, []);

    const handleSubmit = async (values) => {
        const { file_link, file_name, name, type, usage_type, version, file_alias } = values;
        try {
            if (id) {
                await updateDocument({
                    variables: {
                        file: {
                            file_link,
                            name: file_name,
                            version,
                            file_alias,
                        },
                        id,
                        name,
                        type,
                        usage_type,
                    },
                });
            } else {
                await modifyDocument({
                    variables: {
                        file: {
                            file_link,
                            name: file_name,
                            version,
                            file_alias,
                        },
                        name,
                        type,
                        usage_type,
                    },
                });
            }

            message.success(`Successfully ${id ? "updated" : "added"} document.`);
        } catch (error) {
            if (error instanceof Error) {
                message.error(error.message); // Use error.message for more precise messaging
            } else {
                message.error(String(error)); // Fallback in case error is not an instance of Error
            }
        } finally {
            handleSlider();
            getContractList({
                variables: {
                    name: " ",
                    pageSize,
                    page,
                },
            });
        }
    };

    useEffect(() => {
        if (selectedBrand) {
            const { url, brand } = brandsUrls.find(({ brand }) => brand === selectedBrand)!;
            setGeneratedUrl(
                `${url}contracts/?dt=${selected.type || selected.file.type}&ut=${
                    selected.usage_type || selected.file.usage_type
                }&v=${selected.file.version}&fa=${selected.file.file_alias}`
            );
        }
    }, [selectedBrand]);

    return (
        <ContentContainer>
            <Modal
                title="Generate URL"
                visible={isModalOpen}
                footer={null}
                onCancel={handleCancel}
                width={"max-content"}
                zIndex={1000000}
            >
                <Row>
                    <Radio.Group
                        onChange={(e) => setSelectedBrand(e.target.value)}
                        value={selectedBrand}
                        defaultValue={null}
                    >
                        {isAlveoManageContract ? (
                            <Radio value={"alveo"}>Alveo</Radio>
                        ) : (
                            <>
                                <Radio value={"alp"}>Ayala Land Premier</Radio>
                                <Radio value={"avida"}>Avida</Radio>
                                <Radio value={"amaia"}>Amaia</Radio>
                            </>
                        )}
                    </Radio.Group>
                </Row>

                <Row>
                    <StyledClipboard>
                        <span>
                            {generatedUrl ? generatedUrl : "Please select Brand to generate URL"}
                        </span>
                        <CopyOutlined
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    generatedUrl
                                        ? generatedUrl
                                        : "Please select Brand to generate URL"
                                );
                                setIsModalOpen(false);
                                message.success("Copied To Clipboard!");
                            }}
                        />
                    </StyledClipboard>
                </Row>
            </Modal>

            <CustomTable
                createAccess={userPermission(
                    user?.roles[0]?.permissions,
                    "create:contract_document_entry"
                )}
                updateAccess={userPermission(
                    user?.roles[0]?.permissions,
                    "update:contract_document_entry"
                )}
                deleteAccess={userPermission(
                    user?.roles[0]?.permissions,
                    "delete:contract_document_entry"
                )}
                searching={onSearch}
                headingText="List of Documents"
                labelText="documents"
                noStatusBtn={true}
                overrideAddLabel={"Upload Document"}
                handleAddBtn={handleSlider}
                columns={columns}
                noAddBtn={
                    !userPermission(user?.roles[0]?.permissions, "create:contract_document_entry")
                }
                dataSource={documentsList}
                handleSort={handleSort}
                currentPageSize={pageSize}
                handlePageSize={handlePageSize}
                handlePagination={handlePagination}
                totalData={total}
                loading={removeDocumentLoading || getDocumentLoading}
            />

            <CustomDrawer
                visible={viewFiles}
                title={"Previous Uploaded File"}
                onClose={() => setViewFiles(false)}
                width={650}
            >
                <Spin spinning={removeDocumentLoading || getDocumentLoading}></Spin>

                <Table
                    columns={fileColumn}
                    dataSource={
                        contractFiles ? contractFiles.getLinkedUploadFilesByContractEntryId : []
                    }
                />
            </CustomDrawer>

            <CustomDrawer
                visible={showSlider}
                title={`${id ? "Update" : "Upload"} Documents`}
                onClose={handleSlider}
                width={450}
            >
                <Spin spinning={removeDocumentLoading || getDocumentLoading}>
                    <Formik
                        initialValues={initialValue}
                        validationSchema={validationSchema}
                        enableReinitialize={reinitialize}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit(values);
                            resetForm();
                        }}
                        render={(formikBag) => (
                            <Form
                                {...{
                                    formikBag,
                                    handleSlider,
                                }}
                            />
                        )}
                    />
                </Spin>
            </CustomDrawer>

            <PdfViewerModal
                src={viewDocument}
                visible={viewDocument ? true : false}
                onCancel={() => setViewDocument("")}
            />
        </ContentContainer>
    );
};

export default Documents;
