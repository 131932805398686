import React, { useState } from "react";

import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { WrapperForm, MediaVideoContainer } from "./styled";

import { ComponentFormType } from "../content-forms.types";
import ToggleRichText from "../toggleRichText";
import Richtext from "@/components/Richtext";

const ComponentTwo = ({
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
}: ComponentFormType): JSX.Element => {
    const [isRichText, setIsRichText] = useState(true);

    return (
        <WrapperForm>
            <Row gutter={24}>
                <Col span={12}>
                    <MediaVideoContainer
                        value={values.components[index].fields.video}
                        placeholder={"Video Link"}
                        label={"Video"}
                        onChange={(e) => {
                            setFieldValue(`components[${index}].fields.video`, e);
                        }}
                    />
                </Col>
                <Col span={12}>
                    <FormItem
                        label={"Title"}
                        name={`components[${index}].fields.title`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name={`components[${index}].fields.title`} />
                    </FormItem>

                    <ToggleRichText toggleRichText={setIsRichText} isRichText={isRichText} />

                    <FormItem
                        label={"Description"}
                        name={`components[${index}].fields.description`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {isRichText ? (
                            <Richtext
                                toolbar={["heading", "bold", "italic", "link", "insertTable"]}
                                data={values.components[index].fields.description}
                                onChange={(value: string) =>
                                    setFieldValue(
                                        `components[${index}].fields.description`,
                                        value,
                                        false
                                    )
                                }
                            />
                        ) : (
                            <Input.TextArea
                                name={`components[${index}].fields.description`}
                                rows={7}
                            />
                        )}
                    </FormItem>
                </Col>
            </Row>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentTwo;
