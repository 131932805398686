import React from "react";
import { ToggleSlider, ToggleSliderContainer } from "./component-template/styled";

type ToggleRichTextProps = {
    toggleRichText: (val: any) => void;
    isRichText: boolean;
};

function ToggleRichText({ toggleRichText, isRichText }: ToggleRichTextProps): JSX.Element {
    return (
        <ToggleSliderContainer>
            <label htmlFor="checkbox">Rich text field</label>
            <ToggleSlider>
                <label className="switch">
                    <input
                        type="checkbox"
                        onClick={() => toggleRichText((prev) => !prev)}
                        checked={isRichText}
                    />
                    <span className="slider round"></span>
                </label>
            </ToggleSlider>
        </ToggleSliderContainer>
    );
}

export default ToggleRichText;
